import React from 'react'
import {Link} from 'react-router-dom'
import Card from 'react-bootstrap/Card';
    import Button from 'react-bootstrap/Button';
  import '../deal.css'
  // import '../prime.js';

function PrimeCard() {
  return (
   
    <Card style={{ width: '25rem' }}>
    <Card.Img variant="top" src="/prime-day-logo.webp" />
    <Card.Body>
      {/* <Card.Title>Early prime day deals</Card.Title> */}
     <Link to="/myblog/primeday">
      amazon’s october prime day is about to come and we find these deals so far
      </Link>
      {/* <Button variant="dark">Read More</Button> */}
    </Card.Body>
  </Card>     
    
   
      );
    }


export default PrimeCard