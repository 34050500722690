import React, { Component } from 'react';
import { useState } from 'react';
import './Login.css'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useNavigate ,Link} from 'react-router-dom';
import axios from 'axios';
import { Card, CardBody, CardHeader, Container ,Label,Input,FormGroup, Row,Col} from 'reactstrap';
import { doLogin, isLogIn } from '../Auth';
import UserService from '../services/UserService';



const Login=()=>{
  const [loginDetails,setLoginDetails]=useState({
    username:'',
    password:''
  })
  
  const [error,setError]=useState({
    errors:{},
    isError:false
})
const [errormsg,setErrorMsg]=useState('');
const navigate=useNavigate();

  const handleChange=(e,field)=>{
    //dynamic function for onchange
     setLoginDetails({...loginDetails,[field]:e.target.value})
     
 }

 const handleFormSubmit=async(e)=>{
  e.preventDefault();
  // console.log(loginDetails)
  //validation
  if(loginDetails.username.trim()==='' || loginDetails.password.trim()==='' ){
    // toast.error("username and password requared")
    console.log("username and password requared")
    return}

    // console.log("i am in login",loginDetails)        
                
         try{ 
             const response=  UserService.postlogin( loginDetails) 
            //  console.log(response) 
            if((await response).data!==null){
              // console.log((await response).data)
             
              //save the data to the local storage
              doLogin((await response).data,()=>{
                
              })

              //after successful login redirect page to our dashboard
             navigate("/user/dashboard")           
            
            }
            else{
              console.log("error")
            }
          
          }
          
          catch(error){
            console.log(error.message)
           
            setErrorMsg("invalid Username Or Password")
         
          }         

  }   

return( 
<div >
   <Container style={{marginTop:"5%",marginBottom:"50%"}}>
    <Row className="mt-4">
      <Col sm={{size:6, offset:3}}  color="dark">
      <Card>
      <CardHeader>
        Login
      </CardHeader>
      <CardBody>
        <Form onSubmit={handleFormSubmit}>
          {/* email */}
          <FormGroup>
            <Label for="email">
              Email
            </Label>
            <Input
              id="username"
              name="email"
              placeholder="enter username/email"
              type="email"
              value={loginDetails.username}
              onChange={(e)=>handleChange(e,'username')}
              invalid={error.isError}
            />
          </FormGroup>
          {/* password */}
          <FormGroup>
            <Label for="password">
              Password
            </Label>
            <Input
              id="password"
              name="password"
              placeholder="enter password"
              type="password"
              value={loginDetails.password}
              onChange={(e)=>handleChange(e,'password')}
              invalid={error.isError}
            />
          </FormGroup>

          <Container>
            <Button type='submit'>Log In</Button>

          </Container>
          <Label>{errormsg}</Label>

        </Form>
      </CardBody>
      
    </Card>
    <br/>
    <Link to="/">Go to Dinodeal.com</Link>

      </Col>
    </Row>
   
  </Container>
  
</div>
 
  
  )



 

}

export default Login
