import React from 'react'
import {Link} from 'react-router-dom'
import Header1 from '../Header1'
import Footer from '../Footer'
import PrimeCard from './PrimeCard'
import '../deal.css';
import Card from 'react-bootstrap/Card';
    import Button from 'react-bootstrap/Button';
    import Spinner from 'react-bootstrap/Spinner';
    import {Container} from 'react-bootstrap';
//  import '.../download.jfif';


function Blognav() {
  return (
   

      <>
      {/* <Header1 /> */}
      <Container>
        <div className="myrow" >
        <Link to="/">Home </Link> {'>'} Blog 
          <div className="content">

            {/* {togel ? */}
             {/* ( */}
              <div className="leftcolumn">

                <div className="dealsdata" >
                 <PrimeCard/>
                 {/* <PrimeCard/> */}
                 {/* <PrimeCard/> */}
                </div>
                {/* <div className="loadmore"  >
                  {iscompleted ? (
                    <button className='btnLoadmore' onClick={loadMore} type='button'>No Deals To Show</button>
                  ) : (<button className='btnLoadmore' onClick={loadMore} type='button'>Show More</button>)}
                </div> */}
              </div>

            {/* // ) : (


            //   <div className="leftcolumn" style={{ height: "100vh", textAlign: "center" }}>

            //     <Spinner animation="border" />

            //   </div>


            // )} */}
            <div className="rightcolumn"></div>
          </div>
        </div>

        {/* <Footer /> */}
      </Container>
    </>
  
    
  )
}

export default Blognav