import React, { useState,useEffect } from 'react';
import UserService from '../services/UserService';
// import Button from 'react-bootstrap/Button';
import { Link,useParams } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
// import Table from 'react-bootstrap/Table';
import './deal.css';
import Header1 from './Header1'
import ReactLoading from "react-loading";
import Spinner from 'react-bootstrap/Spinner';
import Footer from './Footer';
import { Container } from 'reactstrap';

function  Category (){
  const[deals,setData]=useState([]);
  const [count, setCount] = useState(12)
  const [iscompleted, setIsCompleted] = useState(false);
  const [datalength, Setlength] = useState(-1);  
  let {category} = useParams();     
  useEffect(()=>{

    UserService.getcategoryDeals(category).then((res)=>{
      setData(res.data);
      Setlength(res.data.length);  
    })
    },[category]);
    
    const loadMore = (move) => {    
     
      setCount((count) + 12)
      // console.log(count)
      if (count >= deals.length) {
        setIsCompleted(true)
      } else {
        setIsCompleted(false)
      }
    }
     return(
      <>
      {/* <Header1 /> */}

<Container>


      <div className="myrow" >

        <div className="content">
        {
          datalength>0? (

            // when deals are found datalength =0
            <div className="leftcolumn">
                <div className="pagelink">
                <Link style={{fontStyle:"bold"}} to="/"> Home </Link> {'>>'} {category}
              </div>

            <div className="dealsdata">
              {

                deals?.slice(0, count)?.map((deal, count) =>

                <Card >

                <Link to={'/deal/' + deal.id + '/' + ((deal.commondiscription).replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "")).replace(/\s+/g, '-')} style={{ marginTop: '10px' }}>
                  <Card.Img className="img" variant="top" src={deal.imageurl} style={{ marginLeft: '8%' }} />
                </Link>

                <Card.Body id="body">
                  <Card.Text >
                    {/* <Link to={"/store/" + deal.store.store} >{deal.store.store}</Link> */}
                    <p></p>
                    <Link to={'/deal/' + deal.id + '/' + ((deal.commondiscription).replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "")).replace(/\s+/g, '-')} >
                      <Card.Title id="title" style={{ color: "#171c24" }}>{deal.commondiscription}</Card.Title></Link>

                    {/* <div >{new Date(deal.date).toDateString()}</div> */}
                  </Card.Text>

                </Card.Body>
              </Card>
                ) }
            </div>
            <div className="loadmore"  >
              {iscompleted ? (
                <button className='btnLoadmore' onClick={loadMore} type='button'>No Deals To Show</button>
              ) : (<button className='btnLoadmore' onClick={loadMore} type='button'>Show More</button>)}
            </div>
          </div>):(
            //else if when datalegnth <0 means data is loading else datalegnth =0 no deals
        <div className="leftcolumn" style={{ height: "100vh"}}>
           <div className="pagelink">
                <Link style={{fontStyle:"bold"}} to="/"> Home </Link> {'>>'} {category}
              </div>
          {
            datalength<0?(
              <Spinner animation="border" />
      
            ):(
             <> 
             
            <p>No Deals To Show</p>
            {/* <Link to="/">Go to Dinodeal.com</Link> */}
            </>
            )
          }
         </div> )           
        }       
        
        <div className="rightcolumn"> </div>
        </div>


      </div></Container>
      {/* <Footer /> */}
    </>
     )
  }



export default Category;
