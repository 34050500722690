import React, { useState,useEffect } from 'react';
import UserService from '../services/UserService';

function Update(){

    const [deals,setdata]=useState([]);

    useEffect(() => {
        UserService.getDeals().then((res)=>setdata(res.data))}
        ,[]);
        console.log(deals);
  

    return(
       <div>

        <table>
            {
                deals.map(
                    deal=>
                    <tr>
                        <td>{deal.id}</td>
                    </tr>
                )
            }
        </table>


        </div>
    )

}

export default Update;