import React, { useEffect, useState ,Fragment,navigate} from 'react'
import { Button, Table } from 'reactstrap';
import UserService from '../services/UserService';
// import { ReadOnlyDeals} from './ReadOnlyDeals';
import ReadOnlyDeals from './ReadOnlyDeals';
import EditableRow from './EditableDealRow';
import axios from 'axios';
import { getCurrentUser, getCurrentUserId, getToken } from '../Auth';
import { Link } from 'react-router-dom';


 function EditDeals() {

    const[deals,SetDeals]=useState([]);

    const[editdealId,setEditDealId]=useState(null);
    const[answer,setAnswer]=useState(false);
    const[deleteDealId,setDeleteDealId]=useState(null);
     const[deleteflag,setDeleteFlag]=useState(false);

    useEffect(()=>{
        UserService.getDeals(0,10).then((res)=>SetDeals(res.data))
        console.log("delete flag is"+deleteflag)

    },[])

    const[editFormData,setEditFormData]=useState({
       storeId:"", categoryId:"",
        
        commondiscription:"",
        file:[]
    })
    console.log(deals)
//tacking form value for editing
    const handleEditFormChange=(e)=>{
      
        e.preventDefault();
        const fieldname=e.target.getAttribute("name");
        const fieldvalue=e.target.value;
     
        setEditFormData({...editFormData, [e.target.name]: e.target.value })
     
    }

    const handleEditClick=(event,deal)=>{
        event.preventDefault();
        setEditDealId(deal.id);
        console.log("deal is"+deal.id)
        console.log(deal.store)
        // console.log(deal.imageByte)

        const formValues={
            categoryId:deal.category.category_id,
            storeId:deal.store.store_id,
            commondiscription:deal.commondiscription,
            file:deal.imageurl         

        }
        setEditFormData(formValues)
        console.log(formValues)
    }

    const handelEditFormSubmit=(e)=>{
        e.preventDefault();
      
        console.log("answer is in submitform"+answer)
        if(answer){
          
            const editeddeal={
                Id:editdealId,
                storeId:editFormData.storeId,
                categoryId:editFormData.categoryId,
                commondiscription:editFormData.commondiscription,
                file:editFormData.file
            }
            console.log(editFormData.file)
            console.log(editdealId,editeddeal)
             try { 
              console.log(editeddeal)           
            // axios.put("http://localhost:8080/api/"+editdealId, editeddeal,{
              axios.put("https://dinodealz-backend-bcb469ab952a.herokuapp.com/api/"+editdealId, editeddeal,{
             headers: { Accept: 'application/json',
             'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${getToken()}` }
            })
          
              .then(response => {
                if (response.data != null) {
                    alert("deal Saved successfully and enter Subdeal Data")
                  setEditDealId(null)               
                  }                
                }
              );
          } catch (error) {
           alert(error)
            console.log(error);
          }
        }
        else{
            const editeddeal={
                Id:editdealId,
                storeId:editFormData.storeId,
                categoryId:editFormData.categoryId,
                commondiscription:editFormData.commondiscription,
                // file:editFormData.file
            }
            try {             
                axios.put("https://dinodealz-backend-bcb469ab952a.herokuapp.com/api/deal/"+editdealId, editeddeal,{
                  // axios.put("http://localhost:8080/api/deal/"+editdealId, editeddeal,{
                 headers: { Accept: 'application/json',
                 'Content-Type': 'multipart/form-data',
                  Authorization: `Bearer ${getToken()}` }
                })
                  .then(response => {
                    if (response.data != null) {                
                      alert("deal update successfully")
                      setEditDealId(null)             
                      }                
                    }
                  );
              } catch (error) {
               alert(error)
                console.log(error);
              }
            //   useEffect(()=>{
                // UserService.getDeals().then((res)=>SetDeals(res.data))
        
            // },[])
        }    
    }

    const handelfilechange=(e)=>{
        var check=window.confirm("do you want to save")
        console.log("answer is in handlefilechange"+check)
        setAnswer(check)

       if(check)
       {
        setEditFormData({...editFormData, [e.target.name]: e.target.files[0] })
       }
       console.log("answer is in after handlefilechange"+answer)
        console.log(editFormData)        
    }
//making deal id null for cancel
    const handleCancel=(e)=>{
        setEditDealId(null)

    }

    const handleDeleteClick=(e,deal)=>{

        e.preventDefault();
        
        setDeleteDealId(deal.id)
        console.log("i am in handledelete"+deleteDealId)
        try {             
            axios.delete("https://dinodealz-backend-bcb469ab952a.herokuapp.com/api/deal/"+deal.id,{
              //  axios.delete("http://localhost:8080/api/deal/"+deal.id,{
             headers: { Accept: 'application/json',
             'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${getToken()}` }
            })
              .then(response => {
                if (response.data != null) {
                   alert(response.status)
                     setDeleteDealId(null) 
                    //   setDeleteFlag(true)
                    //   navigate("/user/editdeals")    
                    

                  }                
                }
              );
          } catch (error) {     
           alert(error)
            console.log(error);
          }        
         
    }

    // const changeflag=()=>{
    //     setDeleteFlag(false)
    // }

    
 
  return (
    <>
    <form onSubmit={handelEditFormSubmit}>
  <Table>
    <thead>
        <tr>
            <th>DEAl Id</th>
            <th>Deal Store</th>
             <th>Deal category</th>
            
            <th>Deal title</th>
            <th>Deal image</th>
            <th>Action</th>
        </tr>
    </thead>
    <tbody>
        {
            deals.map((deal)=>(
                
                <Fragment>
                    {
                        editdealId === deal.id ? (
                            <EditableRow editFormData={editFormData} handleEditFormChange={handleEditFormChange}
                             handelfilechange={handelfilechange} answer={answer} handleCancel={handleCancel} />
                        ) : (
                            <ReadOnlyDeals deal={deal} 
                            handleEditClick={handleEditClick} handleDeleteClick={handleDeleteClick} />)
                    }

                <Link to={'/user/editsubdeals/'+deal.id}>subDeal</Link>
                </Fragment>               
            ))

        }
      
    </tbody>
  </Table></form>
    </>
  )
}

export default EditDeals;
