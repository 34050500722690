import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import {Link} from  'react-router-dom';
// import { Container,Nav,NavLink,Navbar,NavItem } from 'reactstrap';
import { doLogOut, getCurrentUser, isLogIn } from '../Auth';


const Dashboard=()=> {

    const[login,setLogin]=useState(false);
    const [user,setUser]=useState([]);
    const [ADMIN,setRole]=useState(false)
    console.log("i am in dashboar"+isLogIn())

    useEffect(()=>{
        console.log(isLogIn())
        setLogin(isLogIn)
        console.log(login)
        setUser(getCurrentUser())
        //  console.log(user.length)
        // if(user.length!=0){
            if(login){
             if(getCurrentUser().roles[0].name=="ROLE_ADMIN"){
                  console.log("Hello ADMIN")
                  setRole(true)
             }
             else
                setRole(false)
        }
       

        
    },[login])


    const logOut=()=>{
        doLogOut(()=>{
            //logged out
            setLogin(false)
        })
    }
  

    
  return (
      <><div>
         {/* <h3>Hello  {user.email}</h3> */}
          <div style={{padding:"20px"}} >
            {
                ADMIN &&
                <>  <h3>Hello ADMIN {user.email}</h3>
                </>
            }
              <Link to="/user/adddeals" style={{margin:"10px",fontSize:"20px"}}>add deals</Link>
              <Link to="/user/editdeals" style={{margin:"10px",fontSize:"20px"}}>Edit Deals</Link>
              {/* <br /> */}
              {
                  ADMIN &&
                  <>                    
                      <Link to='/registration' style={{margin:"10px",fontSize:"20px"}} >register user</Link>
                  </>

              }
              <Link to="/login" onClick={logOut} style={{margin:"10px",fontSize:"20px"}}>LogOut</Link>

          </div>
      </div>
      {/* <div>
        <ul>
            <li>
                <a href="/user/adddeals">Add Deals</a>
            </li>
            <li><a href="/user/editdeals">Edit Deals</a></li>
            <li>
                {
                    ADMIN && 
                    <a href="'/registration'">Register User</a>
                }
            </li>
            <li><a href="/login">log out</a></li>
        </ul>
      </div> */}
      {/* <Container>
              <Nav>
                  <NavItem>
                      <NavLink
                          active>
                          <Link to="/user/adddeals">Add Deals</Link>
                      </NavLink>
                  </NavItem>
                  <NavItem>
                      <NavLink
                          active>
                          <Link to="/user/editdeals">Edit Deals</Link>
                      </NavLink>
                  </NavItem>
                  <NavItem>
                      <NavLink >
                      {
                        ADMIN &&
                        <>

                        <h3>Hello ADMIN {user.email}</h3>
                         <Link to='/registration' >register user</Link>
                        </>

              }
                      </NavLink>
                  </NavItem>
                  <NavItem>
                      <NavLink
                          
                          >
                           <Link to="/login" onClick={logOut}>LogOut</Link>
                      </NavLink>
                  </NavItem>
              </Nav>
      </Container> */}

      
      </>
      
  )
}

export default Dashboard