import React, { useEffect, useState ,Fragment,navigate} from 'react'
import { Button, Table } from 'reactstrap';
import UserService from '../services/UserService';
import ReadOnlySubdeal from './ReadOnlySubdeal';
import EditableSubDealRow from './ReadOnlySubdeal';
import axios from 'axios';
import { getCurrentUser, getCurrentUserId, getToken } from '../Auth';
import { useSearchParams,useParams ,Link} from 'react-router-dom';

function EditSubDeal() {
    const[data,SetDeals]=useState([]);
    const [subdeals,Setsubdeals]=useState([]);
    // const [deal, setData] = useState([]);
 let { id } = useParams();
     useEffect(() => {
        UserService.getDealDetail(id).then((res) => SetDeals(res.data))              
      }, []);
    const[editsubdealId,setEditSubDealId]=useState(null);
    const[answer,setAnswer]=useState(false);
    const[deleteDealId,setDeleteDealId]=useState(null);
     const[deleteflag,setDeleteFlag]=useState(false);   
     const[editFormData,setEditFormData]=useState({
      link:"",
      file:null,
      title:"",
      discription:"",
      commondiscription:"",
      promocode:"",
      active:""  
   })
  // const [editFormData,setEditFormData]=useState([]);
   console.log("editformdata "+editFormData)

      

    const handleEditClick=(e,subdeal)=>{
      e.preventDefault();
      let subid=subdeal.subdeal_id;
      console.log(subid)
      setEditSubDealId(subid);
      console.log("deal is"+subdeal.subdeal_id)
      console.log(subdeal.link)

      const formValues={           
          link:subdeal.link,
          file:subdeal.imageByte,
          title:subdeal.title,
          discription:subdeal.discription,
          commondiscription:subdeal.commondiscription,
          promocode:subdeal.promocode,
          active:subdeal.active        
         }
         console.log(formValues.link)
         console.log(formValues.title)
         let subdealformdata=formValues;
         console.log("subdeal formdata"+subdealformdata)
      setEditFormData(formValues)
      console.log(editFormData)
      console.log("editformdata is"+editFormData.link)
    }

    
    const handleDeleteClick=(e)=>{

    }
    const handelfilechange=(e)=>{
      var check=window.confirm("do you want to save")
      console.log("answer is in handlefilechange"+check)
      setAnswer(check)

     if(check)
     {
      setEditFormData({...editFormData, [e.target.name]: e.target.files[0] })
     }
     console.log("answer is in after handlefilechange"+answer)
      console.log(editFormData)        
  }
    const handleCancel=(e)=>{

    }

    const handleEditFormChange=(e)=>{
      e.preventDefault();
      const fieldname=e.target.getAttribute("name");
      const fieldvalue=e.target.value;
   
      setEditFormData({...editFormData, [e.target.name]: e.target.value })

    }
  return (
    <>
    {id}
    {/* {deal.deal_id} */}
<Table>
   
            <tr>
        <td>
            {data.deal_id}
        </td>
        <td>{data.store}</td>
        <td>{data.category}</td>
        <td>{data.dealcommondiscription}</td>
        <td><img src={`data:image/jpeg;base64,${data.dealImageByte}`} style={{width:'100px'}}></img></td>
    </tr>
  </Table>                  
   <form >
  <Table>
    <thead>
        <tr>
            {/* <th>SubDeal Id</th> */}
            <th>subDeal link</th> <th>Deal image</th>
             <th>subDeal title</th>
            
           
            <th>Deal discription</th>
            <th>Deal commondiscription</th>
            <th>promocode</th>
            <th>Active</th>
            <th>Action</th>
        </tr>
    </thead>
    <tbody>

    
        {
           data.subdeal != null &&
           data.subdeal.map(mysubdeal =>(
            //  deal.subdeals.map((subdeal)=>(
                    
                <Fragment> 
              {/* {mysubdeal.subdeal_id} */}
                    {
                        editsubdealId === mysubdeal.subdeal_id ? (
                            <EditableSubDealRow
                              editFormData={editFormData} handleEditFormChange={handleEditFormChange}
                              handelfilechange={handelfilechange} answer={answer} handleCancel={handleCancel}/>
                        ) : (
                            <ReadOnlySubdeal 
                            mysubdeal={mysubdeal} 
                            handleEditClick={handleEditClick} handleDeleteClick={handleDeleteClick} />
                            
                             )
                    }
                    
                </Fragment>              
            ))
        }
      <Link to={'/user/editmymysubdeals'}>back to EditDeal</Link> 
    </tbody>
  </Table></form>
    </>
  )
}

export default EditSubDeal