import React, { Component ,useState} from 'react';
// import './registration.css'
 import Button from 'react-bootstrap/Button';
 import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { Card, CardBody, CardHeader, Container, Toast ,Input,Label,FormGroup,Row,Col} from 'reactstrap';
import { useEffect } from 'react';
import UserService from '../services/UserService';




    function Registration () {

        
        const [data,setData]=useState({
            firstname:'',
            lastname:'',
            email:'',
            password:'',
        })

        const [error,setError]=useState({
            errors:{},
            isError:false
        })
        useEffect(()=>{
            // console.log(data);

        },[data])

        const handleChange=(e,field)=>{
           //dynamic function for onchange
            setData({...data,[field]:e.target.value})
            //  console.log(data)
        }
        //reseting the form
        const resetdata=()=>{
            setData({ firstname:'',
            lastname:'',
            email:'',
            password:''})
        }

        //submit form
        const submitForm=(e)=>{

            e.preventDefault();
            //data validate

                //server call api
                console.log(data)
                if(error.isError){
                    // Toast.error("Form Data is invalid,correct all details")
                    alert("Form Data is invalid,correct all details")
                    return;
                }


                try {
                   const response=UserService.postregistration(data);

                        if (response != null) {                      
                        resetdata()                        
                         
                          alert("deal Saved successfully")
                        }
                       
                    //   });
                  } catch (error) {
                    console.log(error);
                    //handle error
                    setError({
                        errors:error,
                        isError:true,
                    })
                  }
                //   console.log(error.errors.response.message.firstname)


        }


    
    // render() {
        return (

            <Container style={{marginTop:"5%",marginBottom:"50%"}}>
                <Row className="mt-4">
                    <Col sm={{size:6,offset:3}} color="dark">
                        <Card>

                    <CardHeader>
                       <h1>Fill Information to register!!</h1> 
                       <CardBody>
                        
                       <Form onSubmit={submitForm}>
                        {/* firstname */}
                            <FormGroup>
                                <Label for="firstname">
                                FirstName
                                </Label>
                                <Input
                                id="firstname"
                                name="firstname"
                                placeholder="enter firstname"
                                type="text"
                                onChange={(e)=>handleChange(e,'firstname')}
                                value={data.firstname}
                                // invalid={error.errors.response.data.firstname?true:false}
                                
                                />
                            </FormGroup>
                            {/* lastname */}
                            <FormGroup>
                                <Label for="lastname">
                                LastName
                                </Label>
                                <Input
                                id="lastname"
                                name="lastname"
                                placeholder="enter lastname"
                                type="text"
                                onChange={(e)=>handleChange(e,'lastname')}
                                value={data.lastname}
                                />
                            </FormGroup>
                            {/* email */}
                            <FormGroup>
                                <Label for="email">
                                Email
                                </Label>
                                <Input
                                id="email"
                                name="email"
                                placeholder="enter email"
                                type="email"
                                onChange={(e)=>handleChange(e,'email')}
                                value={data.email}
                                />
                            </FormGroup>
                            {/* password */}
                            <FormGroup>
                                <Label for="password">
                                Password
                                </Label>
                                <Input
                                id="password"
                                name="password"
                                placeholder="enter password"
                                type="password"
                                onChange={(e)=>handleChange(e,'password')}
                                value={data.password}
                                />
                            </FormGroup>
                      

                        <Container className='text-center'>
                      
                            <Button  type='submit'color="dark" >Register</Button>
                            <Button  color="secondary ms-2" onClick={resetdata} >Reset</Button>    
                        </Container>
                         
                    </Form> 
                    </CardBody>
                        
                    </CardHeader>
                </Card>
                    </Col>
                </Row>
                
            </Container>
        )
          
 }

export default Registration;
