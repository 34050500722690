 import React, {  useState,useEffect } from 'react';
 import UserService from '../services/UserService';
 import './mylogo2.png'
 import './Headercss.css';
import { useNavigate } from 'react-router-dom';
function   Header1(){
    
    const [searchstring,setSearchString]=useState("")
    const[categoryData,SetCategory]=useState([]);
    const[storeData,SetStore]=useState([]);
    const navigate=useNavigate();
    useEffect(
        ()=>{  
       UserService.getStore().then((res)=>SetStore(res.data))
       UserService.getCategory().then((res)=>SetCategory(res.data))
      },[]);
       const goSearch=(e)=>{            
            e.preventDefault();
            navigate("/search/"+searchstring) 
       }
       const handleChange=(e)=>{
        setSearchString(e.target.value)
       }
    return (
        <div>
              <div className="wrapper">                             
                    <nav>
                        <input type="checkbox" id="show-search"></input>
                        <input type="checkbox" id="show-menu"></input>
                        <label htmlFor="show-menu" className="menu-icon"><i className="fa fa-bars"></i></label>
                        <div className="content">
                            <div className="logo" id="logo">
                                <a href="/#" style={{color:"rgb(12, 203, 12)"}}> Dino Dealz
                                </a>
                                </div>
                            <ul className="links">
                                <li key='home'><a href="/"  data-name="Home">Home</a></li>
                                <li key='drop1'className='dropdown'>
                                    <a href="/#" className='dropbtn'>Stores <i className="fa fa-angle-down" ></i></a>
                                    {/* <ul className='sublink' > */}
                                    <div className="dropdown-content" style={{width:"400px"}} >
                                    <div className='myrow'>
                                            <div className='column'  >
                                        {storeData.map((data,i)=>                                   
                                        <a href={"/store/" + data.store} data-name={data.store} key={data.store_id}>{data.store}</a>
                                   )}  
                                       </div>
                                        </div>                                     
                                    {/* </ul> */} 
                                    </div>                                    
                                </li>
                            <li key='drop2' className='dropdown' id="categorylink">
                                <a href='/#' className='dropbtn'>Category <i className="fa fa-angle-down" ></i></a>
                                {/* <ul className='sublink' > */}
                                <div className="dropdown-content" style={{ width: "400px" }} >
                                    <div className='myrow'>
                                        <div className='column'  >
                                            {categoryData.map((data, i) =>
                                                <a href={"/category/" + data.category} data-name={data.category} key={i}>{data.category}</a>
                                            )
                                            }
                                        </div>
                                    </div>
                                </div>
                                {/* </ul> */}
                            </li>
                            <li key='Blog'><a href="/blog"  data-name="Home">Blog</a></li>
                               
                            {/* <li id='signup'><a href="https://www.facebook.com/?msclkid=9c3a57ebbc3811ecb783b0cd1da1e5f2" id="facebook">sign up</a></li> */}
                                {/* <li><a href="https://www.facebook.com/?msclkid=9c3a57ebbc3811ecb783b0cd1da1e5f2" id="facebook"><i class="fa fa-facebook"></i></a></li>  */}
                            </ul >
                        </div>
                        <label htmlFor="show-search" className="search-icon"><i className="fa fa-search"></i></label>
                        <form className="search-box" onSubmit={goSearch}>
                            <input type="text" id='inputsearch' onChange={handleChange} name='search'
                             placeholder="Type Something to Search..." required></input>
                             <button type="submit" className="go-icon"><i className="fa fa-arrow-right"></i></button>
                        </form>                        
                    </nav>              
                {/* </header> */}
                </div>
                </div>
    )
}
export default Header1
// const root=ReactDOM.createRoot(document.getElementById('root'))
// root.render(<Header1/>)