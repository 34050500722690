import React, { useState } from 'react'
import axios from "axios";
import { Button, Container, Form, FormControl, FormGroup } from 'react-bootstrap'
import './Signup.css';
import UserService from '../services/UserService';
import { Label, Input, Row } from 'reactstrap';

function SignUp() {
  const [signupdetail, setSignupDetail] = useState({
    email: ""
  });
  const [msg, setMsg] = useState("Thank you for SignUp")
  const [flag, SetFlag] = useState(false)

  const signupdata = async (e) => {
    e.preventDefault();

    if (signupdetail.email.trim === '') {
      console.log("fill the entry")
      return
    }
    try {
      // const response=UserService.postSignUp(signupdetail);
      axios.post("https://dinodealz-backend-bcb469ab952a.herokuapp.com/api/signup/add", signupdetail, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        if (response.data != null) {
          SetFlag(true)
          // resetSubdeal();                     
        }
      }
      );
      // if((await response).data !==null){
      //   console.log("Thank you for SignUp")
      //   
      // }
      // else{
      //   console.log("something went wrong")
      // }
    }
    catch (error) {
      console.log(error.message)
      alert("something went wrong")
    }

    setSignupDetail({
      email: ""
    })
  }

  const handlechange = (e) => {
    setSignupDetail({ ...signupdetail, [e.target.name]: e.target.value })
  }
  return (   
<>
        {
          !flag ? (
          <div className="signUp"> 
                <Form method='post' onSubmit={signupdata} id='form1'>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Sign Up and don't miss any deals </Form.Label>
        <Form.Control type="email" placeholder="Enter email" />
       
        <Form.Text className="text-muted">
          We'll never share your email with anyone else.
        </Form.Text> 
        
      </Form.Group>
      <Container>
        <Button type="submit" style={{backgroundColor:"black", border:"1",borderColor:"green"}}>Sign Up</Button>
      </Container>
      </Form>
           
        </div>):(<div className='signUp'>  <Label >{msg} </Label></div>)
        }
     


</>
  
  )
}

export default SignUp