import React, { useEffect, useState } from 'react';

import './App.css';
// import Header from './component/Header';
import Home from './component/Home2'
// import HomeInsta from './component/HomeInsta'
// import Home2 from './component/Home2'
import Header from './component/Header'
import Footer from './component/Footer';
import AddDeals from './component/AddDeals'
import EditDeals from './component/EditDeals';
import DealDetail from './component/DealDetail'
import Store from './component/Store'
import Category from './component/Category'
import Update from './component/Update'
import Login from './component/Login'
import Registration from './component/Registration'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Dashboard from './Private_page/Dashboard';
import Private from './Private_page/Private';
import EditSubDeal from './component/EditSubDeal';
import Search from './component/Search';
import ContactUs from './component/ContactUs';
import Blognav from './component/Blog/Blognav';
import Prime from './component/Blog/Prime'
import axios from 'axios';
import PrivacyPolicy from './component/PrivacyPolicy';
import { Helmet, HelmetProvider } from 'react-helmet-async'
// import signupjson from './component/EmailSignUp';

// import Footer from './component/Footer';
// import AddCategory from './component/Admin/AddCategory';

function App() {
  // let hideHeader=userlogin ?null :
  // if(window.location.pathname==='/Login') return null;

  // console.log(window.location.pathname)
  const [details, setIp] = useState({});
  const [country1, setcountry] = useState("");
  const helmetContext = {};
  // const getdata = async () => {
  //   const res = await axios.get("https://geolocation-db.com/json/fd18cb60-5f5a-11ee-87d3-bd3f0d7c4f89");
  //   console.log(res.data);
  //   setIp(res.data)

  // }

  useEffect(() => {
    //passing getData method to the lifecycle method
    // getdata()
  }, []);
  return (
    <HelmetProvider context={helmetContext}>

    <div className='app'>



      <BrowserRouter >

        <Header />
        <Routes>


          <Route exact path="/login" element={<Login />} ></Route>
          <Route exact path="/registration" element={<Registration />}></Route>
          <Route exact path="/" element={<Home />} />
          {/* <Route exact path="/links" element={<HomeInsta />} />         */}
          <Route exact path="/store/:store" element={<Store />} />
          <Route exact path="/category/:category" element={<Category />} />
          <Route exact path="/ContactUs" element={<ContactUs />} />
          <Route exact path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route exact path="/search/:searchstring" element={<Search />} />
          <Route exact path="/update" element={<Update />} />
          <Route exact path="/deal/:id/:commondiscription" element={<DealDetail />} />
          <Route exact path="/blog" element={<Blognav />} />
          <Route exact path="myblog/primeday" element={<Prime />} />
          {/* <Route exact path="/signup" element={<signupjson/>} /> */}


          <Route exact path="/user" element={<Private />}>
            <Route exact path="dashboard" element={<Dashboard />} />
            <Route exact path="adddeals" element={<AddDeals />} />
            <Route exact path="editdeals" element={<EditDeals />} />
            <Route exact path="editsubdeals/:id" element={<EditSubDeal />} />

          </Route>

        </Routes>
        {window.location.pathname !== '/login' && window.location.pathname !== '/user/dashboard' && <Footer />}
      </BrowserRouter>
      
      {/* <Helmet>
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2526508676025966"
     crossorigin="anonymous"></script>
            <script>
              {           
                (adsbygoogle = window.adsbygoogle || []).push({});
            }
            </script>
        
          </Helmet> */}
    
      
      </div>
      </HelmetProvider>

      
  );
}
// class App extends Component {
//   render(){
//     return (
//       <div>
//         <BrowserRouter>
//         <Routes>
//           <Route path="/" component={<Home/>}></Route>
//         </Routes></BrowserRouter>
//       </div>
//     )
//   }
// }

export default App;
