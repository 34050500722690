import React, { useState } from 'react'
import {Link} from 'react-router-dom'
import Header1 from './Header1'
import Footer from './Footer';
 import './DealDetail.css';
 import {Form,FormGroup,Col,Input,Label,Button,FormText} from 'reactstrap';
import { Container } from 'react-bootstrap';

function PrivacyPolicy() {
 
   
  return (
    <>

<Container>
        {/* <Header1 /> */}
        <div className="myrow" >
          <div className="content">
            <div className="leftcolumn">
              <div className="pagelink">
                <Link to="/"> Home </Link> {'>'} privacypolicy
              </div>
              <div className="dealsdetail">
                <div className="sociallink">
                <h3>Privacy Policy</h3>
                  {/* <FacebookShareButton
                    url={window.location.href}
                    media={data.imageurl}
                    quotes={" great deal"} hashtag={"#ad"}><FacebookIcon size={32} /></FacebookShareButton>&nbsp;&nbsp;
                  <WhatsappShareButton
                    url={window.location.href}
                    image={data.imageurl}
                    quotes={" great deal"} hashtag={"#ad"}><WhatsappIcon size={32} /></WhatsappShareButton>*/}
                
                </div> 
                <div className='title'>
               <p> </p>
               <p></p>
               <p></p>
              
             
               <p>
                  Privacy Policy for www.dinodealz.com 
                <br/>

                <br/>If you require any more information or have any questions about our privacy policy, please feel free to contact us by email at dinodealz18@gmail.com<br/>

                <br/>At www.dinodealz.com, the privacy of our visitors is of extreme importance to us.<br/>

                <br/><h5>Cookies and Web Beacons</h5>
                      www.dinodealz.com currently  does not use cookies.</p>

                </div>
                <div id="subtitle">
                  <p>We may receive a small payment from an affiliate if you click a link to purchase a product</p>
                </div>
                <div className="divider">

                </div>  
                </div>
            </div>
                       <div className="rightcolumn1"> </div>
          </div>
        </div>
      </Container>
    
    </>
  )
}

export default PrivacyPolicy