// import { Cookies, useCookies } from "react-cookie";
// const [cookies,setcookie,removeCookie]=useCookies(['logintocken'])

//isloggedIn
export const isLogIn=()=>{
   
    let data=localStorage.getItem("data")
    //  console.log(data)
    if(data==null){
        // console.log("i am null")
        return false
    }else{
        return true;
    }
}


//doLogin => data => set to local storage
export const doLogin=(data,next)=>{
   localStorage.setItem("data",JSON.stringify(data)); 
//    setcookie('logintocken',JSON.stringify(data),{path:'/login'})
// console.log(data)
   next();
}
//do Logout=> remove from localstorage

export const doLogOut=(next)=>{
    localStorage.removeItem("data")
    next();
}
//get current User

export const getCurrentUser=()=>{
    if(isLogIn()){
        // console.log(localStorage.getItem("data").user);
        return JSON.parse(localStorage.getItem("data")).user;
    }else{
        return undefined;
    }
}
export const getCurrentUserId=()=>{
     if(isLogIn()){
        return JSON.parse(localStorage.getItem("data")).user.user_id;
        
    }else{
        return undefined;
    }
}

export const getToken=()=>{
    if(isLogIn()){
        //  console.log((localStorage.getItem("data")).token);
        return JSON.parse(localStorage.getItem("data")).token;
    }else{
        return null;
    }
}

