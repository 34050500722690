import React, {useEffect,useState} from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import storedata from './MyData.json';
import Categorydata from './MyCategory.json';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import UserService from '../services/UserService';
import { useNavigate } from 'react-router-dom';

import './Headercss.css';

function Header() {
  

    const [searchstring,setSearchString]=useState("")
    const[categoryData,SetCategory]=useState([]);
    const[storeData,SetStore]=useState([]);
    const navigate=useNavigate();
    useEffect(
        ()=>{  
       UserService.getStore().then((res)=>SetStore(res.data))
       UserService.getCategory().then((res)=>SetCategory(res.data))
      },[]);
       const goSearch=(e)=>{ 
        console.log("im in searcj")           
            e.preventDefault();
            navigate("/search/"+searchstring) 
       }
       const handleChange=(e)=>{
        setSearchString(e.target.value)
       }

        return (
          <Navbar expand="lg" className="tertiary" id='navbardropdown' >
            <Container>
              <Navbar.Brand href="/" style={{color:"green",style:"bold",fontSize:"30px"}}>Dino Dealz</Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav"  />
              <Navbar.Collapse  >
                <Nav className="me-auto">
                  <Nav.Link href="/" id='navbardropdown'>Home</Nav.Link>
                  <Nav.Link href="/blog" id='navbardropdown'>Blog</Nav.Link>
                  <NavDropdown title="Stores" id='navbardropdown'>
                    {storeData.map((data, i) =>
                      <NavDropdown.Item href={"/store/" + data.store} id='navbardropdown'>{data.store}</NavDropdown.Item>
                    )}
                  </NavDropdown>
                  <NavDropdown title="Category" id='navbardropdown'>
                    {categoryData.map((data, i) =>
                      <NavDropdown.Item href={"/category/" + data.category} id='navbardropdown'>{data.category}</NavDropdown.Item>
                      )}
                  </NavDropdown>
                </Nav>
                <Form className="d-flex" >
                  <Form.Control
                    type="text"
                    placeholder="Search"
                    className="me-2"
                    aria-label="Search" onChange={handleChange}/>
                  <Button variant="outline-success"  onClick={goSearch}>Search</Button>
                </Form>
              </Navbar.Collapse>
            </Container>
          </Navbar>

     
            
        );


    }


export default Header;