import React,{useEffect,useState} from 'react'
import UserService from '../services/UserService';
import { Dropdown } from 'reactstrap';

const EditableRow = ({editFormData,handleEditFormChange,handelfilechange,answer,handleCancel}) => {
    const[categoryData,SetCategory]=useState([]);
    const[storeData,SetStore]=useState([]);
    
    useEffect(()=>{
  
       UserService.getStore().then((res)=>SetStore(res.data))
       UserService.getCategory().then((res)=>SetCategory(res.data))
      },[]);
    //    console.log(categoryData) 
    //    console.log(storeData)

    // console.log(editFormData.file)

   const handleChange=()=>{

    }
   const fileChange=()=>{

    }
  return (
    <tr> 
        {/* <td><div>
          <label>{editFormData.Id}</label>
        </div>
            </td> */}
        <td>
       
        <div>
            <select name="storeId" onChange={handleEditFormChange} value={editFormData.storeId}>
            <option disabled value={0}>Select Store</option>
                      {storeData.map((data) =>
                        <option key={data.store_id} value={data.store_id} >{data.store}</option>)}
            </select>
        </div>
        </td>
        <td>
            <div>
                <select name="categoryId" onChange={handleEditFormChange} value={editFormData.categoryId}>
                <option disabled value={0}>Select Category</option>
                {categoryData.map((data, i) =>
                <option key={data.category_id} value={data.category_id}>{data.category}</option>)}  
                </select>
            </div>
        </td>
        <td>
        <input type="text"
            required="required"
            placeholder='Enter a commondiscription'
            name='commondiscription'
            onClick={handleEditFormChange}
            value={editFormData.commondiscription}>
            </input>
        </td>
        <td>
            {!answer &&
            <>
               <p>{answer}</p>
            <img src={editFormData.file} style={{width:'100px'}}></img>
               </>  
            } 
        
        <input id="fileSelect" type="file" name='file' onChange={handelfilechange}  />
        </td>
        
        <td><button type="submit">save</button>
        <button onClick={handleCancel}>cancel</button>
        </td>        
    </tr>
  )
}

export default EditableRow