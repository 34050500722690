import axios from 'axios';
import { getToken } from '../Auth';

// export const BASE_URL="http://localhost:8080/api"
export const BASE_URL="https://dinodealz-backend-bcb469ab952a.herokuapp.com//api/"

export const myAxios=axios.create({
    baseURL:BASE_URL
})

export const privateAxios=axios.create({
    baseURL:BASE_URL
})

// export const categoryData=

// export const postDataApi = async (deal) => {
//     const config = {
//     headers: {
//     "Content-type": "multipart/form-data",
//     "Authorization": `Bearer ${getToken()}`,
//     },
//     };
//     const res = await axios.post(`${BASE_URL}/update`, deal, config);
//     return res.data;
// } 

privateAxios.interceptors.request.use(config=>{
    const token=getToken()
    console.log(token)
    if(token){
        // config.headers.common.Authorization=`Bearer ${token}`
        config.Authorization=`No Auth`
        config.headers.Authorization=`Bearer ${token}`
        console.log(config)
        return config
    }

},error=>Promise.reject(error))

// axios.interceptors.request.use(
//     (config) => {
//         const token =getToken();

//         if (token) {
//             config.headers['Authorization'] = `Bearer ${token}`;
//         }

//         return config;
//     },

//     (error) => {
//         return Promise.reject(error);
//     }
// );

export const createDeal=async (deal)=>{
    const response = await privateAxios
        .post(BASE_URL + "/update", deal);
    return response.data;
};

export const getCategory=async()=>{
    const response=await privateAxios.get(BASE_URL+"/category/");
    return response.data;
};

// export default new DealService()


