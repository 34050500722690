import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { isLogIn } from '../Auth'

const  Private=()=> {

   const name="";

//    let loggedIn=true
  
    if( isLogIn()){
        //  name=localStorage.getItem("data").user.firstname;
console.log("i am in private true")
        return <Outlet/>
    }
    else{
        console.log("i am in private false")
        return <Navigate to={"/login"}/>
    }
 
}

export default Private
