import React from 'react'
import { Link } from 'react-router-dom'

function ReadOnlymysubdeal({mysubdeal,handleEditClick,handleDeleteClick}) {
  return (

   
    <tr>
    {/* <td>{mysubdeal.mysubdeal_id}</td> */}
     <td>{mysubdeal.link}</td>
     <td><img src={`data:image/jpeg;base64,${mysubdeal.imageByte}`} style={{width:'100px'}}></img></td>
    <td>{mysubdeal.title}</td>   
    <td>{mysubdeal.discription}</td>                 
    <td>{mysubdeal.commondiscription}</td>
   
    
    <td>{mysubdeal.promocode}</td>
    <td>{mysubdeal.active}</td>
    
   {/* <td><button id={deal.id}>delete</button></td> */}
   <td><button id='btnedit'  onClick={(event)=>handleEditClick(event,mysubdeal)}>Edit</button></td>
   {/* <td><button id='btndelete' onClick={(event)=>handleDeleteClick(event,deal)}>Delete</button></td>  */} 
    <Link to="/user/dashboard" onClick={(event)=>handleDeleteClick(event,mysubdeal)}>Delete</Link>   

    {/* <Link to={'/dealdetail/' + deal.id} >mysubdeal</Link>              */}
    
    
</tr>
  )
}

export default ReadOnlymysubdeal