import React from 'react'
import { Link } from 'react-router-dom'

 function ReadOnlyDeals ({deal,handleEditClick,handleDeleteClick,handlesubdealClick})  {

  
  return (
    
   
  //  <>
              <tr>
                    {/* <td>{deal.id}</td> */}
                     <td>{deal.store.store}</td>
                    <td>{deal.category.category}</td>                    
                    <td>{deal.commondiscription}</td>
                   
                    <td><img src={deal.imageurl} style={{width:'100px'}}></img></td>
                    
                   {/* <td><button id={deal.id}>delete</button></td> */}
                   <td><button id='btnedit'  onClick={(event)=>handleEditClick(event,deal)}>Edit</button></td>
                   {/* <td><button id='btndelete' onClick={(event)=>handleDeleteClick(event,deal)}>Delete</button></td>  */} 
                    <Link to="/user/dashboard" onClick={(event)=>handleDeleteClick(event,deal)}>Delete</Link>   

                    {/* <Link to={'/dealdetail/' + deal.id} >SubDeal</Link>              */}
                    <Link to={'/user/editsubdeals/'+deal.id}>subDeal</Link>
                    
                </tr>
              
               
   
   
                
       
  )
}
export default ReadOnlyDeals;
