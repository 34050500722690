import React, {useState,useEffect } from 'react';
import UserService from '../services/UserService';

import {Link,useParams } from 'react-router-dom';
import Card from 'react-bootstrap/Card';

import './deal.css';
import Header1 from './Header1'
import Footer from './Footer';

function  Search (){
  const[deals,setData]=useState([]);
  // const deta=[];
  // deta=useState([]);
  let { searchstring } = useParams();
  const [count, setCount] = useState(12)
  const [iscompleted, setIsCompleted] = useState(false);
  const[togel,settogel]=useState(false);
  // let {category} = useParams();
  // console.log(category) 
  // console.log(searchstring)  
  useEffect(()=>{

    UserService.getSearchDeals(searchstring).then((res)=>{
      setData(res.data);
      if(res.data.length!==0){
        settogel(true);
      }
      })
    // UserService.getcategoryDeals(category).then((res)=>setData(res.data))
    },[searchstring]);
    //  console.log(deals)  
     
     return(
      <>
      {/* <Header1/> */}
      <div className="myrow" >
       
        <div className="content">    


          <div className="leftcolumn">
            
            <div className="dealsdata">
              {

                deals?.slice(0, count)?.map((deal, count) =>

                  <Card >

                    <Link to={'/deal/' + deal.id + '/' + ((deal.commondiscription).replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "")).replace(/\s+/g, '-')} style={{ marginTop: '10px' }}>
                      <Card.Img className="img" variant="top" src={deal.imageurl} style={{ marginLeft: '8%' }} />
                    </Link>

                    <Card.Body id="body">
                      <Card.Text >
                        <Link to={"/store/" + deal.store.store} >{deal.store.store}</Link>
                        <p></p>
                        <Link to={'/deal/' + deal.id + '/' + ((deal.commondiscription).replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "")).replace(/\s+/g, '-')} >
                          <Card.Title id="title" style={{color:"#171c24"}}>{deal.commondiscription}</Card.Title></Link>
                        {/* <div >{new Date(deal.date).toDateString()}</div> */}
                      </Card.Text>
                    
                    </Card.Body>
                  </Card>
                )
              }
            </div>
            {/* <div className="loadmore"  >
              {iscompleted ? (
                <button className='btnLoadmore' onClick={loadMore} type='button'>No Deals To Show</button>
              ) : (<button className='btnLoadmore' onClick={loadMore} type='button'>Show More</button>)}
            </div> */}
          </div>
          <div className="rightcolumn"> 
          {/* <div className='rightdata'>
             <ol className='olright'>                        
                        <li style={{listStyle:"none"}}><a href="https://www.facebook.com/profile.php?id=61550555985381" target='_blank' rel="noreferrer"><i className="fa fa-facebook" aria-hidden="true"></i> </a></li>
                        <li><a href="https://www.facebook.com/groups/dinodealzandcouponz" target='_blank' rel="noreferrer"><i className="fa fa-group" aria-hidden="true"></i> </a></li>
                        <li><a href="https://www.pinterest.com/Dinodealz/" target='_blank' rel="noreferrer"><i className='fa fa-pinterest'></i> </a></li>
                        <li><a href="https://www.instagram.com/dinodealzandcouponz/"><i className="fa fa-instagram" aria-hidden="true"></i> </a></li>
                    </ol>
            </div> */}
       
         
          </div>
        </div>




      </div>
     <Footer/>
      </> 
     )
  }



export default Search;
