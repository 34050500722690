import React, { Component, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import dealdata from './MyData.json';
import categorydata from './MyCategory.json';
import { Card, CardBody, CardHeader, Container, FormGroup, Input, Label, ToastBody } from 'reactstrap';
import { getCurrentUser, getCurrentUserId, getToken } from '../Auth';
import { useEffect } from 'react';
import { json } from 'react-router-dom';
import UserService from '../services/UserService';
import { createDeal, postDataApi ,getCategory} from '../services/DealService';
import axios from 'axios';


function AddDeals() {

  const [deal, setDeal] = useState({
    storeId: 0,
    categoryId: 0,
    file: '',
    commondiscription:''   
  },[])
  const[displayDeal,SetDisplayDeal]=useState([]);

  const[togelDeal,SetTogelDeal]=useState(false);
  const[subDeal,setSubDeal]=useState({
     link: '', 
    imagefile: '',
     title: '',
    price: '',
    regularprice:'',
  discription:'',
    promocode: '',
    active: '',
    // date:new Date(),
    endingdate: '',
    
  })

  const[categoryData,SetCategory]=useState([]);
  const[storeData,SetStore]=useState([]);
  useEffect(()=>{

     UserService.getStore().then((res)=>SetStore(res.data))
     UserService.getCategory().then((res)=>SetCategory(res.data))
    },[subDeal]);
  

  const[user,setUser]=useState(undefined)
  const changingTogelDeal=(e)=>{
    SetTogelDeal(false)
  }

  const resetSubdeal = (e) => {
      setSubDeal({
      link: '', 
    imagefile: '',
     title: '',
    subdealdiscription: '',   
    subdealcommondiscription:'',
    promocode: '',
    active: '',
    // date:new Date(),
    endingdate: '',
    })

  }
  const [error, setErrors] = useState({
    errors: {},
    isError: false
  }) 

  const handleChange = (e) => { 
    setDeal({...deal, [e.target.name]: e.target.value })   
  } 
  const subdealhandleChange = (e) => {  
    
    setSubDeal({...subDeal, [e.target.name]: e.target.value })
    //  console.log(subDeal)
  }
//++++++++++++++++submit deal
  const submitDeal = (e) => {    
    e.preventDefault();
    var today=new Date().getDate();
    // console.log(deal)
    setDeal({...deal,date:today})
    if (error.isError) {    
      // Toast.error(error.errors);
      console.log(error.errors)
      return;
    }
      try { 
        // axios.post("http://localhost:8080/api/deal/"+getCurrentUser().user_id, deal,{  
         axios.post("https://dinodealz-backend-bcb469ab952a.herokuapp.com/api/deal/"+getCurrentUser().user_id, deal,{
          headers: { Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
           Authorization: `Bearer ${getToken()}` }
         })      
           .then(response => {
             if (response.data != null) {
            //  console.log(response.data)
             SetDisplayDeal(response.data)
               alert("deal Saved successfully")
               SetTogelDeal(true);
               resetdeal();
               }              
             }
           );
       } catch (error) {
        alert(error)
         console.log(error);
       }
    }
    //+++++++++++++++++++submit subdeal
    const submitSubDeal = (e) => {    
      e.preventDefault();  
      var today=new Date().getDate();
      // console.log(subDeal)
      setDeal({...subDeal,date:today})
      // console.log("deal id is"+displayDeal.id)
      if (error.isError) {          
        console.log(error.errors)
        return;
      }  
        try {             
           axios.post("https://dinodealz-backend-bcb469ab952a.herokuapp.com/api/subdeal/"+displayDeal.id, subDeal,{
            // axios.post("http://localhost:8080/api/subdeal/"+displayDeal.id, subDeal,{
            
            headers: { Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
             Authorization: `Bearer ${getToken()}` }
           })       
             .then(response => {
               if (response.data != null) {     
                 alert("deal Saved successfully and enter Subdeal Data")             
                 resetSubdeal();                     
                 }                
               }
             );
         } catch (error) {
          alert(error)
           console.log(error);
         }
      }
  const handleValidation = () => {
    console.log(" i am in handlevalidation")
    if (!deal.storeId) {
      setErrors({
        errors:"store is blank",
        isError:true
      })
    }

    // console.log(this.state.category)
    if (!deal.categoryId) {
      setErrors({
        errors:"store is blank",
        isError:true
      })
    }   
    if (!deal.link) {
      setErrors({
        errors:"store is blank",
        isError:true
      })
    }

    console.log(this.state.image)
    if (!deal.image) {
      setErrors({
        errors:"store is blank",
        isError:true
      })
    }
    if (!deal.title) {
      setErrors({
        errors:"store is blank",
        isError:true
      })
    }

    if (!deal.discription) {
      setErrors({
        errors:"store is blank",
        isError:true
      })
    }
    return error;
  }
  const fileChange=(e)=>{    
    setDeal({ ...deal, [e.target.name]: e.target.files[0] })  
  }
  const subdealfileChange=(e)=>{    
    setSubDeal({ ...subDeal, [e.target.name]: e.target.files[0] })  
  }
  const resetdeal = () => {
    setDeal({
      store: '',
      category: '',     
      image: '',     
      commondiscription: '',      
    })
  }

if(!togelDeal){
 
  return(<>
      <Container>
        <Card >
          <CardHeader>
            <h3>Enter Deals Details</h3>
          </CardHeader>
          <CardBody>
            {/* {JSON.stringify(deal)} */}

            {/* about deal data form */}
            <Form method='post' id="form1" onSubmit={submitDeal}>
              <Row>
                <Col md={3}>
                  <FormGroup >
                    {/* <Label for="example select">Select Store</Label> */}
                    <Input id="storeSelect" name="storeId" type="select" onChange={handleChange} defaultValue={0}>
                      <option disabled value={0}>Select Store</option>
                      {storeData.map((data) =>
                        <option key={data.store_id} value={data.store_id} >{data.store}</option>)}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup >
                    {/* <Label for="example select">Select Category</Label> */}
                    <Input id="categorySelect" name="categoryId" type="select" onChange={handleChange} defaultValue={0}>
                      <option disabled value={0}>Select Category</option>
                      {categoryData.map((data, i) =>
                        <option key={data.category_id} value={data.category_id}>{data.category}</option>)}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup >
                    {/* <Label>Select your image</Label> */}
                    <Input id="fileSelect" type="file" name='file' onChange={fileChange} />
                  </FormGroup>
                </Col> </Row>
              {/*common description */}
              <FormGroup >
                <Label>CommonDiscription</Label>
                <Input id="commondiscripationSelect" type="text" name='commondiscription' onChange={handleChange} />

              </FormGroup>

              <Container className="text-center">
                <Button variant="primary" style={{ marginRight: "10px" }} type="submit" >
                  Submit
                </Button>
                <Button variant="primary"  style={{ margin: "10px" }} >
                  Reset
                </Button>

                <Button variant="primary" style={{ margin: "10px" }} >Cancel
                </Button>
              </Container>
              </Form>
          </CardBody>
        </Card>
      </Container>
    </>
                     )
  
}else{
  return(
    <>
    <p>{displayDeal.category.category}                   {displayDeal.store.store}</p>
    
   <p>{displayDeal.commondiscription}
    <img  src={displayDeal.imageurl} style={{width:'150px'}}></img></p>
      <Card id="cardSubdeal"  >
        <CardHeader>Enter SubDeal</CardHeader>
        <CardBody>
          <Form method='post' id="form2" onSubmit={submitSubDeal}>
            {/* link */}
            <FormGroup>
              <Label>Link</Label>
              <Input id="emailSelect" value={subDeal.link} type="text" name='link' onChange={subdealhandleChange} />
            </FormGroup>

            {/* subdeal image */}
            <FormGroup >
              <Label>Select your image</Label>
              <Input id="fileSelect"  type="file" name='imagefile' onChange={subdealfileChange} />
            </FormGroup>
            {/* title */}

            <FormGroup >
              <Label>Title</Label>
              <Input id="titleSelect" value={subDeal.title} type="text" name='title' onChange={subdealhandleChange} />

            </FormGroup>

            {/* description */}
            <FormGroup >
              <Label>Price</Label>
              <Input id="priceSelect" value={subDeal.price} type="text" name='price' onChange={subdealhandleChange} />
            </FormGroup>
            <FormGroup >
              <Label>Price</Label>
              <Input id="regularpriceSelect" value={subDeal.regularprice} type="text" name='regularprice' onChange={subdealhandleChange} />
            </FormGroup>
            {/* common discription */}
            <FormGroup >
              <Label> Discription</Label>
              <Input id="subdealcommondiscripationSelect" value={subDeal.subdealdiscription} type="text" name='subdealdiscription' onChange={subdealhandleChange} />
            </FormGroup>

            <Row>
              <Col md={6}>
                {/* promocode */}
                <FormGroup as={Col} >
                  <Form.Label>Promocode</Form.Label>
                  <Input id="promocodeSelect" type="text" value={subDeal.promocode} name='promocode' onChange={subdealhandleChange} placeholder="Promocode" />

                </FormGroup>
              </Col>
              <Col md={6}>
                {/* radio button active or inactive */}
                <FormGroup check onChange={subdealhandleChange}>
                  <Input name="radio1" type="radio" onChange={subdealhandleChange} />
                  {''}
                  <Label check> active</Label>
                </FormGroup>

                <FormGroup check onChange={subdealhandleChange}>
                  <Input name="radio1" type="radio" onChange={subdealhandleChange} />
                  {''}
                  <Label check> Inactive</Label>
                </FormGroup>
              </Col>
            </Row>
            <Label>Select Deal End Date and Time </Label>
            <Row>
              <Col md={6}>
                <FormGroup >
                  <Input id="dateSelect" type="date" name="endingdate" onChange={subdealhandleChange}></Input>
                </FormGroup>
              </Col>
              <Container className="text-center">
                <Button variant="primary" style={{ marginRight: "10px" }} type="submit" >
                  Add Subdeal
                </Button>
                <Button variant="primary" type="reset" style={{ margin: "10px" }} >
                  Reset
                </Button>

                <Button variant="primary" style={{ margin: "10px" }} >Cancel
                </Button>
                <Button variant="primary" style={{ marginRight: "10px" }} type="submit" onClick={changingTogelDeal} >
                  Add New DEAL
                </Button>
              </Container>
            </Row>
          </Form>
          </CardBody>
          </Card>             
           
                  
  
      </>)
}

}
export default AddDeals;