import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Header1 from './Header1'
import Footer from './Footer';
import './DealDetail.css';
import { Form, FormGroup, Col, Input, Label, Button, FormText } from 'reactstrap';
import UserService from '../services/UserService';
import { Container } from 'react-bootstrap';
import axios from 'axios';


function ContactUs() {
  const [contactdetail, setcontactdetail] = useState({
    email: "",
    name: '',
    subject: '',
    message: '',
    post: 'contactus'

  });
  const [flag, SetFlag] = useState(false)
  const [msg, setMsg] = useState("Thank you for contacting us. We will serve you shortly.")

  const contactdetails = async (e) => {
    e.preventDefault();
    if (contactdetail.email.trim === '' || contactdetail.name.trim === '' || contactdetail.subject.trim === '' || contactdetail.message.trim === '') {
      console.log("fill out all entry")
      return
    }
    console.log(contactdetail)
    try {
      // const response=UserService.postContactUs(contactdetail);
      // if((await response).data!==null){
      //   console.log("thank you")

      // }
      // else{
      //   console.log("error")
      // }
      axios.post("https://dinodealz-backend-bcb469ab952a.herokuapp.com/api/contactus/add", contactdetail, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        if (response.data != null) {
          SetFlag(true)
          alert("Thank You")

          // console.log("thank you")
          // resetSubdeal();                     
        }
        else {
          console.log("error")
        }
      }
      );


    }
    catch (error) {
      console.log(error.message)
      alert("something went wrong")
    }

    setcontactdetail({
      email: "",
      name: '',
      subject: '',
      message: '',
      date: new Date()
    })
  }
  const handlechange = (e) => {
    setcontactdetail({ ...contactdetail, [e.target.name]: e.target.value })
  }
  return (
    <>
      {/* <Header1 /> */}

      <Container>
        {/* <Header1 /> */}
        <div className="myrow" >
          <div className="content">
            <div className="leftcolumn">
              <div className="pagelink">
                <Link to="/"> Home </Link> {'>'} Contact US
              </div>
              <div className="dealsdetail">
                <div className="sociallink">
                  <h3>We would like to hear from you</h3>


                </div>
                <div className='title'>
                {
          !flag ? (
                  <div>

                    <Form onSubmit={contactdetails}>
                      <FormGroup row>
                        <Label
                          for="exampleEmail"

                        >
                          Email
                        </Label>
                        <Col mb={5}>
                          <Input
                            id="exampleEmail"
                            name="email"
                            placeholder="enter your Email"
                            type="email" required
                            onChange={handlechange}
                            value={contactdetail.email}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label
                          for="examplePassword"
                        >
                          Enter your name
                        </Label>
                        <Col mb={5}>
                          <Input
                            id="name"
                            name="name"
                            placeholder="enter your name"
                            type="text"
                            value={contactdetail.name}
                            onChange={handlechange}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label
                          for="Subject"

                        >
                          Subject
                        </Label>
                        <Col mb={5}>
                          <Input
                            id="exampleSelect"
                            name="subject"
                            type="select"
                            value={contactdetail.subject}
                            onChange={handlechange}
                          >
                            <option>
                              Ask about a deal?
                            </option>
                            <option>
                              Give feedback on our site
                            </option>
                            <option>
                              Other
                            </option>

                          </Input>
                        </Col>
                      </FormGroup>

                      <FormGroup row>
                        <Label
                          for="exampleText"
                        >
                          Message (required)
                        </Label>
                        <Col mb={5}>
                          <Input
                            id="exampleText"
                            name="message"
                            type="textarea" required
                            value={contactdetail.message}
                            onChange={handlechange}
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup
                        check
                        row
                      >
                        <Col
                          sm={{
                            offset: 2,
                            size: 10
                          }}
                        >
                          <Button >
                            Submit
                          </Button>
                        </Col>
                      </FormGroup>
                    </Form>
                    {/* <div> */}
                    <h3>Or</h3>

                    <h4> <a href="mailto:dinodealz18@gmail.com"> Click to send an email</a></h4>

                  </div>):(
                  <div className='signUp'>  <Label >{msg} </Label>
                  <br/>Check out all deals  <Link to="/"><b>Home</b>  </Link></div>)
        }

                </div>
                {/* <div id="subtitle">
                  <p>We may receive a small payment from an affiliate if you click a link to purchase a product</p>
                </div> */}
                <div className="divider">

                </div>
              </div>
            </div>
            <div className="rightcolumn1"> </div>
          </div>
        </div>
      </Container>

      {/* <Footer/> */}
    </>
  )
}

export default ContactUs